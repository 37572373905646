const others = [
  {
    path: '/others/enquiries',
    name: 'others-enquiries',
    component: () => import('@/views/transactions/others/enquiries/Enquiries.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/others/subscriptions',
    name: 'others-subscriptions',
    component: () => import('@/views/transactions/others/subscriptions/Subscriptions.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/others/feedbacks',
    name: 'others-feedbacks',
    component: () => import('@/views/transactions/others/feedbacks/Feedbacks.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default others
