const cars = [
  {
    path: '/cars/selfdrive',
    name: 'cars-selfdrive',
    component: () => import('@/views/cars/selfdrive/SelfDrive.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/cars/chauffeur',
    name: 'cars-chauffeur',
    component: () => import('@/views/cars/chauffeurdrive/ChauffeurDrive.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default cars
