// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Production
const firebaseConfig = {
  apiKey: "AIzaSyATxdKXJsnmIjn7845VYc1H-Y3LqlJFlxM",
  authDomain: "hypeprod-f3a90.firebaseapp.com",
  databaseURL: "https://hypeprod-f3a90.firebaseio.com",
  projectId: "hypeprod-f3a90",
  storageBucket: "hypeprod-f3a90.appspot.com",
  messagingSenderId: "55995415459",
  appId: "1:55995415459:web:bb9f0de43b22b805",
  measurementId: "G-MPM9G4NLFC"
 }
const imagekitConfig={
  publicKey: 'mhRs9YESOPRT2KSCLNXgJRU7h+g=',
  urlEndpoint: 'https://ik.imagekit.io/egrcxziyv',
  authenticationEndpoint: 'https://asia-east2-hypeprod-f3a90.cloudfunctions.net/imagekitauth',
}

 // TEST

//  const firebaseConfig = {
//   apiKey: "AIzaSyCh4OSOXt0HFYIN4P2HxEE_B_PLlD7M5Q4",
//   authDomain: "hypetestclone.firebaseapp.com",
//   databaseURL: "https://hypetestclone-default-rtdb.firebaseio.com",
//   projectId: "hypetestclone",
//   storageBucket: "hypetestclone.appspot.com",
//   messagingSenderId: "320138992654",
//   appId: "1:320138992654:web:eb26e225f6dad55c09305f",
//   measurementId: "G-ZPVFWV0FBJ"
// };


// const imagekitConfig={
//     publicKey: 'public_xQM6v/fy+6kLwnHhjDskI2RDlrE=',
//     urlEndpoint: 'https://ik.imagekit.io/k4wc1vm23h',
//     authenticationEndpoint: 'https://asia-east2-hypetestclone.cloudfunctions.net/imagekitauth',
//   }


// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const analytics = getAnalytics(app)
const db = getFirestore(app)

export { auth, analytics, db, imagekitConfig }
