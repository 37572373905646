const masters = [
  {
    path: '/masters/carbrand',
    name: 'masters-carbrand',
    component: () => import('@/views/masters/carbrand/CarBrand.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/masters/carfeature',
    name: 'masters-carfeature',
    component: () => import('@/views/masters/carfeature/CarFeature.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/masters/location',
    name: 'masters-location',
    component: () => import('@/views/masters/location/Location.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/masters/agents',
    name: 'masters-agents',
    component: () => import('@/views/masters/agent/Agent.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/masters/carfleet',
    name: 'masters-carfleet',
    component: () => import('@/views/masters/carfleet/CarFleet.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default masters
