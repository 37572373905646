import { mdiAccountGroupOutline, mdiArchiveOutline, mdiCreditCardOutline } from '@mdi/js'

export default [
  {
    subheader: 'TRANSACTIONS',
  },
  {
    title: 'Bookings',
    icon: mdiCreditCardOutline,
    // badge: 'New',
    badgeColor: 'success',
    children: [
      {
        title: 'Car',
        to: 'bookings-car',
      },
      {
        title: 'Yacht',
        to: 'bookings-yacht',
      },
    ],
  },
  {
    icon: mdiAccountGroupOutline,
    title: 'Customers',
    to: 'page-customers',
  },
  {
    title: 'Others',
    icon: mdiArchiveOutline,
    children: [
      {
        title: 'Enquiries',
        to: 'others-enquiries',
      },
      {
        title: 'Subscriptions',
        to: 'others-subscriptions',
      },
      {
        title: 'Feedbacks',
        to: 'others-feedbacks',
      },
    ],
  },
]
