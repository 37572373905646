<template>
  <v-menu
    offset-y
    nudge-bottom="22"
    min-width="175"
    left
    :elevation="$vuetify.theme.dark ? 9 : 8"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        class="d-flex align-center"
        v-on="on"
      >
        <v-img
          :src="locales.find(l => l.title === $store.state.appConfig.country).img"
          :alt="$store.state.appConfig.country"
          height="14px"
          width="22px"
          class="me-2"
        ></v-img>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ $store.state.appConfig.country }}</span>
      </div>
    </template>

    <!-- Options List -->
    <v-list>
      <v-list-item-group
        :value="$store.state.appConfig.country"
        @change="updateCountry"
      >
        <v-list-item
          v-for="locale in locales"
          :key="locale.title"
          :value="locale.title"
        >
          <v-img
            :src="locale.img"
            height="14px"
            width="22px"
            :alt="locale.title"
            class="me-2"
          ></v-img>
          <v-list-item-title>{{ locale.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import store from '@/store'

export default {
  setup() {
    const { country } = useAppConfig()

    const locales = [
      {
        title: 'India',
        img: 'https://ik.imagekit.io/egrcxziyv/indiaflagimage.png',
        locale: 'en',
      },
      {
        title: 'UK',
        img: 'https://ik.imagekit.io/egrcxziyv/ukflagimage.png',
        locale: 'fr',
      },
      {
        title: 'UAE',
        img: 'https://ik.imagekit.io/egrcxziyv/uaeflagimage.png',
        locale: 'ar',
      },
    ]

    const updateCountry = selectedCountry => {
      country.value = selectedCountry
    }

    return {
      locales,
      country,
      updateCountry,
    }
  },
}
</script>
