import { mdiViewDashboard } from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiViewDashboard,
    // badge: '3',
    // badgeColor: 'warning',
    to: 'dashboard-crm',
    // children: [
    //   {
    //     title: 'CRM',
    //     to: 'dashboard-crm',
    //   },
    //   {
    //     title: 'Analytics',
    //     to: 'dashboard-analytics',
    //   },
    //   {
    //     title: 'eCommerce',
    //     to: 'dashboard-eCommerce',
    //   },
    // ],
  },
]
