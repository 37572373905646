const bookings = [
  {
    path: '/bookings/car',
    name: 'bookings-car',
    component: () => import('@/views/bookings/carbookings/CarBookings.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/bookings/yacht',
    name: 'bookings-yacht',
    component: () => import('@/views/bookings/yachtbookings/YachtBookings.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default bookings
